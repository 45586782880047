import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import TermsPages from '../partials/Terms/About'
import { dataTermsSCR } from '../data/terms/terms-scr-consultation-authorization'

const TermsSCRPage = () => (
  <>
    <GatsbySeo nofollow={true} noindex={true} />
    <TermsPages
      data={dataTermsSCR}
      linkCanonical="termos-autorizacao-consulta-scr"
    />
  </>
)

export default TermsSCRPage
