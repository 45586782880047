import { TermsProps } from '../../@interface/terms'

export const dataTermsSCR: TermsProps = {
  title:
    'Termo de Autorização para Consulta SCR',
  subtitle: 'Termo de Autorização para Consulta SCR',
  update: '09 de Setembro de 2024',
  about: {
    title: 'Termo',
  },
  section: [{
    list: [{
      head: 'A Cora Sociedade de Crédito, Financiamento e Investimento S.A. (“CORA”) comunica a Você que:',
      items: [
        {
          item: 'Todos e quaisquer débitos e responsabilidades decorrentes de quaisquer operações com características de crédito realizadas pela Sua Empresa e integrantes de seu respectivo quadro societário junto à CORA serão registrados no Sistema de Informações de Créditos (“SCR”) administrado pelo Banco Central do Brasil (“Bacen”) e/ou outro sistema que em virtude de norma legal o substitua ou complemente;',
        },
        {
          item: 'O SCR tem por finalidades (i) prover informações ao Bacen, para fins de monitoramento do crédito no sistema financeiro e para o exercício de suas atividades de fiscalização; e (ii) propiciar o intercâmbio de informações entre instituições financeiras, conforme definido no § 1º do art. 1º da Lei Complementar nº 105, de 10 de janeiro de 2001, sobre o montante de responsabilidades de clientes em operações de crédito, com o objetivo de subsidiar decisões de crédito e de negócios;',
        },
        {
          item: 'Sua Empresa e os integrantes de seu respectivo quadro societário poderão ter acesso aos dados constantes em seu nome no SCR, por meio do Registrato – Extrato de Registro de Informações no Bacen ou na Central de Atendimento ao Público do Bacen;',
        },
        {
          item: 'As manifestações de discordância quanto às informações constantes no SCR e os pedidos de correção, exclusão e registro de medidas judiciais no SCR deverão ser dirigidos a CORA, por meio de requerimento escrito e fundamentado enviado por Sua Empresa e/ou pelos integrantes de seu respectivo quadro societário, acompanhado da respectiva decisão judicial, conforme aplicável; e',
        },
        {
          item: 'A consulta sobre qualquer informação do SCR pelas instituições autorizadas pelo Bacen dependerá de prévia autorização do cliente de operações de crédito.',
        },
        {
          item: 'Você, neste ato, declara-se ciente do comunicado acima e expressamente autoriza a CORA a consultar e registrar os débitos e responsabilidades decorrentes de operações com características de crédito, que constem ou venham a constar em nome da Sua Empresa e dos integrantes de seu respectivo quadro societário, no SCR e/ou outro sistema que em virtude de norma legal o substitua ou complemente. Você concorda, ainda, em estender a presente autorização de consulta ao SCR às demais instituições autorizadas a consultá-lo e que adquiriram ou receberam em garantia, ou manifestem interesse de adquirir ou receber em garantia, total ou parcialmente, operações de crédito sob sua responsabilidade.',
        },
        {
          item: 'Por fim, Você anui com a formalização desta autorização por meio de todas as formas em direito admitidas, incluindo meios eletrônicos e digitais como válidos e plenamente eficazes, ainda que seja estabelecida assinatura e aceitação eletrônica ou certificação fora dos padrões ICP – Brasil, conforme disposto pelo art. 10 da Medida Provisória nº 2.200/2001 em vigor no Brasil.',
        },
      ],
    },]
  }]
}
